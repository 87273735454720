import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentService } from 'src/app/services/api/document.service';
import { CommonService } from 'src/app/services/api/common.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import messages from 'src/assets/messages/cmr.json';
import { get } from 'http';

@Component({
  selector: 'vex-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss']
})
export class SendMailComponent implements OnInit {

  docId: string;
  exp: number;
  form: FormGroup;
  expandedReceiver: number | null = null;
  brokerList: any[] = [];
  languages: string[] = [];
  selectedLanguage: string = 'es';
  selectedContacts: { [key: number]: any[] } = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<SendMailComponent>,
    private fb: FormBuilder,
    private documentService: DocumentService,
    private commonService: CommonService<any, any>,
    private alert: ToastService
  ) {}

  ngOnInit() {
    this.docId = this.defaults["id"];
    this.initializeForm();
    this.getData();
  }

  initializeForm() {
    this.form = this.fb.group({
      receivers: this.fb.array([]),
    });
  }

  getData() {
    this.documentService.getDocumentEmail(this.defaults).subscribe(response => {
      if (response?.isSuccessful) {
        this.defaults = response.data;
        this.exp = this.defaults.exp;
        this.brokerList = this.defaults.brokerList || [];

        const receiversArray = this.form.get('receivers') as FormArray;
        this.defaults.receivers.forEach(receiver => {
          receiversArray.push(this.createReceiverGroup(receiver));
        });

        this.getLanguages();
      }
    });
  }

  getLanguages() {
    
    const receiverLanguages = this.receivers.controls
      .map(ctrl => {
        const useBroker = ctrl.get('sendToBroker').value;
        const entity = useBroker ? ctrl.get('broker').value : ctrl.get('provider').value;
        return entity?.language?.name;
      })
      .filter((lang, index, self) => lang && self.indexOf(lang) === index);
  
    this.languages = messages.messages
      .map(msg => msg.language)
      .filter(lang => receiverLanguages.includes(lang));
  
    Object.keys(this.form.controls).forEach(controlName => {
      if (controlName !== 'receivers' && !this.languages.includes(controlName)) {
        this.form.removeControl(controlName);
      }
    });
  
    this.languages.forEach(lang => {
      if (!this.form.contains(lang)) {
        const message = messages.messages.find(msg => msg.language === lang)?.message || '';
        this.form.addControl(lang, this.fb.control(message));
      }
    });
  }
  

  createReceiverGroup(receiver: any): FormGroup {
    return this.fb.group({
      provider: [receiver.provider],
      broker: [receiver.broker || ''],
      contact: [receiver.provider.contacts ? [receiver.provider.contacts[0]] : []],
      contactEmails: this.fb.array([]),
      sendToBroker: [false],
      selectedBroker: [receiver.broker?.name || ''],
      vehicles: [receiver.vehicles]
    });
  }

  getContacts(index: number) {
    const receiver = this.receivers.at(index).value;
    return receiver.sendToBroker ? receiver.broker?.contacts || [] : receiver.provider.contacts || [];
  }

  changeReceiver(index: number) {
    const receiverGroup = this.receivers.at(index);
    const sendToBroker = !receiverGroup.get('sendToBroker').value;
    receiverGroup.get('sendToBroker').setValue(sendToBroker);

    const contacts = sendToBroker
      ? receiverGroup.get('broker')?.value?.contacts || []
      : receiverGroup.get('provider')?.value?.contacts || [];
  
    // Dejamos el campo vacío para que el usuario elija
    receiverGroup.get('contact')?.setValue('');
  
    // Limpiar el array de emails (inputs debajo)
    const contactEmailsArray = receiverGroup.get('contactEmails') as FormArray;
    contactEmailsArray.clear();

    this.selectedContacts[index] = [];
    this.selectedContacts = { ...this.selectedContacts };

    this.getLanguages();
  }

  onBrokerSelected(index: number, brokerName: number) {
    const broker = this.brokerList.find(b => b.name === brokerName);
    if (broker) {
      const receiverGroup = this.receivers.at(index);
      receiverGroup.get('broker').setValue(broker);
      receiverGroup.get('contact').setValue(broker.contacts?.[0]?.email || '');
    }
  }

  getSelectedContacts(index: number) {
    return this.selectedContacts[index] || [];
  }
  
  onContactSelectionChange(contacts: any[], index: number) {
    this.selectedContacts[index] = contacts;
  
    const contactEmailsFormArray = this.receivers.at(index).get('contactEmails') as FormArray;
    contactEmailsFormArray.clear(); // Limpiar anteriores
  
    contacts.forEach(contact =>
      contactEmailsFormArray.push(this.fb.control(contact.email))
    );

  }

  getVehicles(index: number) {
    return this.defaults.receivers[index]?.vehicles || [];
  }

  toggleVehicles(index: number) {
    this.expandedReceiver = this.expandedReceiver === index ? null : index;
  }

  get receivers(): FormArray {
    return this.form.get('receivers') as FormArray;
  }

  validateEmails(): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValid = true;

    this.form.value.receivers.forEach(receiver => {

      if (receiver.contactEmails.length > 0) {

        receiver.contactEmails.forEach(contact => {
          if (!emailRegex.test(contact)) {
            isValid = false;
          }
        });

      } else if (!receiver.contact || !emailRegex.test(receiver.contact)) {
        isValid = false;
      }
    });
  
    return isValid;
  }

  send() {
    if (!this.validateEmails()) {
      this.alert.error("Hay correos electrónicos que no son válidos.");
      return;
    }
  
    const formValue = this.form.value;
    const data = {
      docId: this.docId,
      exp: this.exp,
      receivers: formValue.receivers.map(receiver => {
        let destinatario;
        if (receiver.sendToBroker) {
          destinatario = receiver.broker;
        } else {
          destinatario = receiver.provider;
        }
    
        return {
          destinatario: destinatario,
          contact: receiver.contactEmails.length > 0 ? receiver.contactEmails : receiver.contact,
          vehicles: receiver.vehicles
        };
      }),
      languages: this.languages.map(lang => ({
        language: lang,
        message: formValue[lang]?.replace(/\n/g, "<br>")
      }))
    };
  
    this.commonService.any("documents/send", data).subscribe({
      next: response => {
        if (!response) {
          this.alert.error("No ha sido posible enviar la documentación");
        } else if (!response.isSuccessful) {
          this.alert.info(response.message);
        } else {
          this.alert.info("Documentación enviada con éxito");
          this.dialogRef.close(response);
        }
      },
      error: e => {
        this.alert.error("Error en el envío de la documentación.");
      }
    });
  }
}

import { Observable } from "rxjs";
import { IFilters } from "src/app/interfaces/ifilters";
import { ComboItem } from "src/app/models/combo-item";
import { BrokerType } from "./broker-type";
import { ClientType } from "./client-type";
import { CommercialType } from "./commercial-type";
import { FreightCompanyType } from "./freight-company-type";
import { ExternalServiceType } from "./externalService-type";
import { ProviderType } from "./provider-type";


export interface IContactCostTypes {
    getFilters(): Observable<IFilters> ;
}

export const contactCostTypes = new Map([
  [1, BrokerType],
  [2, CommercialType],
  [5, ClientType],
  [3, ProviderType],
  [4, FreightCompanyType],
  [6, ExternalServiceType]
]);

export const contactsCostTypes = new Map([
  [null, ""],
  [1, "1"],
  [2, "0"],
  [5, "4"],
  [3, "2"],
  [4, "3"],
  [6, "5"]
]);

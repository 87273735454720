<form [formGroup]="form">
  <div class="flex items-center" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <h2 class="headline m-0 flex-auto">Envío de correo</h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-dialog-content class="flex flex-col">
    <div class="grid grid-cols-11 gap-4">
      <span class="col-span-1"></span>
      <span class="flex items-center uppercase font-normal text-gray-500 text-left col-span-3">Destinatario</span>
      <span class="flex items-center uppercase font-normal text-gray-500 text-left col-span-3">Contacto</span>
      <span class="flex items-center uppercase font-normal text-gray-500 text-left col-span-2"></span>
      <span class="flex items-center uppercase font-normal text-gray-500 text-left col-span-2 pl-4">Vehículos</span>
    </div>

    <ng-container formArrayName="receivers">
      <ng-container *ngFor="let receiver of receivers.controls; let i = index" [formGroupName]="i">
        <div class="grid grid-cols-11 gap-4 py-2">
          <span class="flex items-center justify-self-center col-span-1">
            <button (click)="toggleVehicles(i)">
              <mat-icon svgIcon="mat:keyboard_arrow_down" *ngIf="expandedReceiver !== i"></mat-icon>
              <mat-icon svgIcon="mat:keyboard_arrow_up" *ngIf="expandedReceiver === i"></mat-icon>
            </button>
          </span>

          <!-- Campo de Proveedor o Broker -->
          <span class="flex items-center col-span-3">
            <ng-container *ngIf="!receiver.value.sendToBroker">
              <input type="text" matInput [value]="receiver.value.provider.name" readonly>
            </ng-container>
            <ng-container *ngIf="receiver.value.sendToBroker">
              <input type="text" matInput 
                [formControlName]="'selectedBroker'"
                [matAutocomplete]="autoBrokers"
                placeholder="Seleccionar broker">
              <mat-autocomplete #autoBrokers="matAutocomplete" (optionSelected)="onBrokerSelected(i, $event.option.value)">
                <mat-option *ngFor="let broker of brokerList" [value]="broker.name">
                  {{ broker.name }}
                </mat-option>
              </mat-autocomplete>
            </ng-container>
          </span>

          <!-- Campo de Contacto -->
          <span class="flex items-center col-span-3">
            <div *ngIf="getContacts(i).length == 0" class="border-b border-gray-300 max-w-[200px]">
              <input matInput [formControlName]="'contact'" placeholder="Seleccionar contacto">
            </div>

            <ng-container *ngIf="getContacts(i).length > 0">
              <div [formGroup]="receivers.at(i)">
                <div class="border-b border-gray-300 max-w-[200px]">
                  <mat-select multiple (selectionChange)="onContactSelectionChange($event.value, i)"
                    [value]="getSelectedContacts(i)" placeholder="Seleccionar contactos">
                    <mat-option *ngFor="let contact of getContacts(i)" [value]="contact">
                      {{ contact.name }}
                    </mat-option>
                  </mat-select>
                </div>
            
                <!-- Inputs para editar correos -->
                <div formArrayName="contactEmails">
                  <div *ngFor="let ctrl of receivers.at(i).get('contactEmails')['controls']; let j = index">
                    <div appearance="fill" class="w-full max-w-[300px]">
                      <input matInput [formControlName]="j" placeholder="Correo" class="mt-[.5rem]"/>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </span>

          <!-- Checkbox para cambiar entre proveedor y broker -->
          <span class="flex items-center col-span-2">
            <mat-checkbox *ngIf="receiver.value.broker" color="primary" (change)="changeReceiver(i)">Enviar a broker</mat-checkbox>
          </span>

          <span class="flex items-center col-span-2 pl-4">
            {{ getVehicles(i)?.length || 0 }}
          </span>
        </div>
        <!-- Vehicles List (Expandable) -->
        <div *ngIf="expandedReceiver === i" class="grid grid-cols-3 gap-2 pb-4">
          <span class="flex items-center uppercase font-normal text-gray-500 pl-6">Nombre</span>
          <span class="flex items-center uppercase font-normal text-gray-500 pl-6">Matrícula</span>
          <span class="flex items-center uppercase font-normal text-gray-500 pl-6">Bastidor</span>
          <ng-container *ngFor="let vehicle of getVehicles(i)">
            <span class="flex items-center pl-6">{{ vehicle.name }}</span>
            <span class="flex items-center pl-6">{{ vehicle.plate || '' }}</span>
            <span class="flex items-center pl-6">{{ vehicle.vin || '' }}</span>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    

    <!-- Cuerpo del Mensaje -->
    <mat-tab-group>
      <mat-tab *ngFor="let lang of languages" [label]="lang.toUpperCase()">
        <div class="w-full mt-6">
          <mat-form-field class="w-full">
            <mat-label>Cuerpo del mensaje ({{ lang.toUpperCase() }})</mat-label>
            <textarea matInput [formControlName]="lang"></textarea>
          </mat-form-field>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancelar</button>
    <button color="primary" mat-flat-button type="submit" (click)="send()" [disabled]="!form.valid">
      <mat-icon class="mr-2" svgIcon="mat:send"></mat-icon>
      <span>Enviar</span>
    </button>
  </mat-dialog-actions>
</form>

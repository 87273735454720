<vex-page-layout>

  <vex-page-layout-header *ngIf="isMenu" class="flex flex-col items-start">
    <div [class.container]="layoutCtrl.value === 'boxed'" [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
      class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class="title mt-2 mb-1">Documentos</h1>
        <vex-breadcrumbs [crumbs]="['Gestión documental', 'Documentos']"></vex-breadcrumbs>
      </div>

       <!--   <div class="hidden sm:block">
        <mat-button-toggle-group [formControl]="layoutCtrl" class="mt-4 sm:mt-0">
          <mat-button-toggle value="boxed">Reducir</mat-button-toggle>
          <mat-button-toggle value="fullwidth">Ampliar</mat-button-toggle>
        </mat-button-toggle-group>
      </div> -->
    </div>
  </vex-page-layout-header>
  <vex-page-layout-header *ngIf="!isMenu" class="h-24 flex flex-col items-start justify-center">

  </vex-page-layout-header>

  <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
    [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">

    <div class="card overflow-auto -mt-16">
      <div *ngIf="this.params?.disabledMode && editabled" class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
        <h2 *ngIf="params.element"
          class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
          <span *ngIf="selection.isEmpty()">{{this.params.element}}</span>
          <span *ngIf="selection.hasValue()">{{ selection.selected.length }}
            <span *ngIf="selection.selected.length === 1">{{this.params.element.toLowerCase()}}</span>
            <span *ngIf="selection.selected.length > 1">{{this.params.title.toLowerCase()}}</span> seleccionado<span
              *ngIf="selection.selected.length > 1">s</span></span>
        </h2>
        <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r flex-none">
          <button (click)="showDeleteDocumentsAlert(selection.selected)" color="primary" mat-icon-button
            matTooltip="Borrar documentos seleccionados" type="button">
            <mat-icon svgIcon="mat:delete"></mat-icon>
          </button>
        </div>

        <span class="flex-1"></span>
        <span class="flex-1"></span>
        <span class="flex-1"></span>
        <span class="flex-1"></span>
        <span class="flex-1"></span>

        <!--  <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
          <mat-icon svgIcon="mat:more_vert"></mat-icon>
        </button>-->        <button [matMenuTriggerFor]="columnFilterMenu" class="ml-4 flex-none" mat-icon-button
          matTooltip="Columnas visibles" type="button">
          <mat-icon svgIcon="mat:filter_list"></mat-icon>
        </button>

        <button (click)="close()" class="text-secondary" mat-dialog-close mat-icon-button type="button">
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>

        <button *ngIf="editabled && documentType != 'Vehículo'" (click)="createDocument()" class="ml-4 flex-none" color="primary" mat-mini-fab
          matTooltip="Añadir Documento" type="button">
          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>
      </div>
      <div *ngIf="this.params?.disabledMode === undefined"
        class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
        <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
          <span *ngIf="selection.isEmpty()">Documento</span>
          <span *ngIf="selection.hasValue()">{{ selection.selected.length }}
            documento<span *ngIf="selection.selected.length > 1">s</span> seleccionado<span
              *ngIf="selection.selected.length > 1">s</span></span>
        </h2>

        <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r flex-none">
          <button (click)="showDeleteDocumentsAlert(selection.selected)" color="primary" mat-icon-button
            matTooltip="Borrar documentos seleccionados" type="button">
            <mat-icon svgIcon="mat:delete"></mat-icon>
          </button>

          <!-- <button color="primary" mat-icon-button matTooltip="Another action" type="button">
            <mat-icon svgIcon="mat:folder"></mat-icon>
          </button> -->
        </div>

        <div
          class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
          <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
          <input [(ngModel)]="filters.nameLike" (ngModelChange)="pageIndex=0;loadData();"
            class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="Buscar nombre" type="search">
        </div>

        <div
          class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
          <mat-icon class="icon-sm text-secondary" svgIcon="mat:search" ></mat-icon>
          <mat-select [(ngModel)]="filters.documentCategoryId" (ngModelChange)="pageIndex=0;loadData();"
            placeholder="Seleccionar categoría" class="px-4 py-2 border-0 outline-none w-full bg-transparent">
            <mat-option *ngFor="let item of categories" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
          <mat-icon class="icon-sm text-secondary"  *ngIf="filters?.documentCategoryId"
            (click)="filters.documentCategoryId=null; loadData(); $event.stopPropagation()" svgIcon="mat:close">
          </mat-icon>
        </div>
        <div *ngIf="!documentType"
          class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
          <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
          <mat-select #docType [(ngModel)]="filters.documentTypeId" placeholder="Seleccionar tipo"
            (ngModelChange)="pageIndex=0;loadData();" class="px-4 py-2 border-0 outline-none w-full bg-transparent">
            <mat-option *ngFor="let item of docTypes" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
          <mat-icon class="icon-sm text-secondary" *ngIf="filters.documentTypeId" (click)="filters.documentTypeId=null; filters.referenceName=null;loadData(); $event.stopPropagation()" svgIcon="mat:close"></mat-icon>
        </div>
        <div *ngIf="!reference && filters.documentTypeId"
        class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
        <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
        <input [(ngModel)]="filters.referenceName" (ngModelChange)="pageIndex=0;loadData();"
          class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="Buscar referencia" type="search">
      </div>
       <!--  <button (click)="loadData()" class="ml-4 flex-none" color="primary" mat-mini-fab matTooltip="Buscar Documentos"
          type="button">
          <mat-icon svgIcon="mat:search"></mat-icon>
        </button> -->

        <span class="flex-1"></span>

        <!--  <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
          <mat-icon svgIcon="mat:more_vert"></mat-icon>
        </button>-->
        <button *ngIf="editabled" [matMenuTriggerFor]="columnFilterMenu" class="ml-4 flex-none" mat-icon-button
          matTooltip="Columnas visibles" type="button">
          <mat-icon svgIcon="mat:filter_list"></mat-icon>
        </button>

        <button *ngIf="editabled" (click)="createDocument()" class="ml-4 flex-none" color="primary" mat-mini-fab
          matTooltip="Añadir Documento" type="button">
          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>
      </div>

      <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort (matSortChange)="sortData($event)">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Checkbox Column -->
        <ng-container matColumnDef="checkbox">
          <th *matHeaderCellDef mat-header-cell>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary">
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" class="w-4" mat-cell>
            <mat-checkbox (change)="$event ? selection.toggle(row) : null" (click)="$event.stopPropagation()"
              [checked]="selection.isSelected(row)" color="primary">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Image Column -->
        <ng-container matColumnDef="image">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
            <img [src]="row['imageSrc']" class="avatar h-8 w-8 align-middle">
          </td>
        </ng-container>

        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'select'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property]?.name }}</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'number'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="text-right uppercase" mat-header-cell> {{ column.label }}</th>
            <td class="text-right" *matCellDef="let row" [ngStyle]="{'color':getColor(row[column.property])}" mat-cell>{{ row[column.property] || 0 | number:'1.0-2':'es'}} €</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] | date:'dd/MM/yyyy' }}</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'chip'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td mat-cell *matCellDef="let row of ">
              <mat-chip-list>
                <span *ngFor="let element of row[column.property] ">
                  <mat-chip *ngIf="element"
                    class="leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary/10">
                    <mat-chip-avatar *ngIf="column.property === 'tenants'"><img class="avatar-image"
                        src="{{'assets/img/flags/' + element + '.jpg'}}" />
                    </mat-chip-avatar>{{element}}
                  </mat-chip>
                </span>
              </mat-chip-list>
            </td>

          </ng-container>
          <ng-container *ngIf="column.type === 'flag'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation();showDeleteDocumentAlert(row)"
                [(ngModel)]="row[column.property]"
                class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover"
                [class.mat-checkbox-disabled]="false" disabled>
              </mat-checkbox>
            </td>
          </ng-container>
        </ng-container>

        <ng-container matColumnDef="view">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
            <div class="flex justify-center items-center">
              <mat-icon class="w-5" svgIcon="mat:open_in_new" (click)="viewFile(row);$event.stopPropagation()"></mat-icon>
            </div>
          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
            <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ element: row }"
              [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
              <mat-icon svgIcon="mat:more_horiz"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr (click)="updateDocument(row)" *matRowDef="let row; columns: visibleColumns;" @fadeInUp
          class="hover:bg-hover trans-ease-out" [ngClass]="{'cursor-pointer': editabled}"  mat-row></tr>
      </table>

      <mat-paginator showFirstLastButtons 
        [pageSizeOptions]="pageSizeOptions" 
        [pageSize]="pageSize" 
        (page)="onPaginateChange($event)"
        [length]="this.total"
        class="sticky left-0"></mat-paginator>
    </div>

  </vex-page-layout-content>

</vex-page-layout>


<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <div (mouseleave)="closeMenu(columnFilterMenu)">
    <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
      class="checkbox-item mat-menu-item">
      <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
        {{ column.label }}
      </mat-checkbox>
    </button>
    <button style="float:right;" mat-button (click)="closeMenu(columnFilterMenu)">Aceptar</button>
  </div>
</mat-menu>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-element="element" matMenuContent>
   <!--  <a href="{{element.url}}" target="_blank" download #downloadClick></a> -->
    <button (click)="downloadFile(element)" mat-menu-item>
      <mat-icon svgIcon="mat:download"></mat-icon>
      <span>Descargar</span>
    </button>
    <button *ngIf="this.documentType != 'Vehículo'" (click)="updateDocument(element)" mat-menu-item>
      <mat-icon svgIcon="mat:edit"></mat-icon>
      <span>Editar</span>
    </button>
    <button *ngIf="this.documentType != 'Vehículo'" (click)="showDeleteDocumentAlert(element)" mat-menu-item>
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>Borrar</span>
    </button>
  </ng-template>
</mat-menu>


<!-- <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>Imprimir</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:download"></mat-icon>
    <span>Exportar</span>
  </button>

    <button mat-menu-item>
    <mat-icon svgIcon="mat:delete"></mat-icon>
    <span>Eliminar</span>
  </button>
</mat-menu> -->
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AdminGuard } from './guards/admin.guard';
import { CanDeactivateGuard } from './services/utils/form-guard.service';


const routes: Routes = [
  {
    path: 'login',
    //pathMatch :'prefix',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),

  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'new-password',
    loadChildren: () => import('./pages/pages/auth/new-password/new-password.module').then(m => m.NewPasswordModule),
  },
  /*  { path: '**', redirectTo: '/login', pathMatch: 'full'}, */
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'offers/equipments',

        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'equipments',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador','Gestor de Ofertas']
        },
        loadChildren: () => import('./pages/pages/offers/equipments/equipments.module').then(m => m.EquipmentsModule),
      },
      {
        path: 'offers/vehicles',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'vehicles',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador','Gestor de Ofertas']
        },
        loadChildren: () => import('./pages/pages/offers/vehicles/vehicles.module').then(m => m.VehiclesModule),
      },
      {
        path: 'commercial/contacts',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'contacts',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador', 'Comercial de Compra', 'Comercial de Venta', 'Gestor de Transporte', 'Manager','Gestor de Administración','Gestor de Ofertas', 'Gestor Documental']
        },
        loadChildren: () => import('./pages/pages/commercial/contacts/contacts-table.module').then(m => m.ContactsTableModule),
      },
      {
        path: 'commercial/paysCharges',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'paysCharges',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador','Gestor de Administración','Gestor de Entidades Bancarias']
        },
        loadChildren: () => import('./pages/pages/administration/pays-charges/pays-charges-table.module').then(m => m.PaysChargesTableModule),
      },
      {
        path: 'administration/invoices',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'invoices',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador','Gestor de Administración']
        },
        loadChildren: () => import('./pages/pages/administration/invoices/invoices-table.module').then(m => m.InvoicesModule),
      },
      {
        path: 'administration/transactions',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'transactions',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador','Gestor de Entidades Bancarias']
        },
        loadChildren: () => import('./pages/pages/administration/banks/banks-table.module').then(m => m.BanksTableModule),
      },
      {
        path: 'commercial/costs',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'costs',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador','Gestor de Administración','Gestor de Entidades Bancarias']
        },
        loadChildren: () => import('./pages/pages/administration/costs/costs-table.module').then(m => m.CostsTableModule),
      },
      {
        path: 'administration/users',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'users',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador']
        },
        loadChildren: () => import('./pages/pages/administration/users/users-table.module').then(m => m.UsersTableModule),

      },
      {
        path: 'administration/warehouse',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'warehouse',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador','Gestor de Administración']
        },
        loadChildren: () => import('./pages/pages/administration/warehouse/warehouse-table.module').then(m => m.WarehouseTableModule),

      },
      {
        path: '',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador', 'Gestor Documental']
        },
        children: [
          {
            path: 'administration/masters/articles',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'articles',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor de Tablas Maestras']
            },
            //resolve: { data : MasterCommon},
            loadChildren: () => import('./pages/pages/administration/masters/articles/articles.module').then(m => m.ArticlesModule),
          },
          {
            path: 'administration/masters/banks',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'banks',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor de Entidades Bancarias']
            },
            loadChildren: () => import('./pages/pages/administration/masters/banks/banks.module').then(m => m.BanksModule),
          },
          {
            path: 'administration/masters/brokers',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'brokers',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador', 'Comercial de Compra', 'Comercial de Venta', 'Gestor de Transporte', 'Manager','Gestor de Administración','Gestor de Ofertas', 'Gestor Documental']
            },
            //loadChildren: () => import('./pages/pages/commercial/contacts/contacts-table.module').then(m => m.ContactsTableModule),
            loadChildren: () => import('./pages/pages/administration/masters/brokers/brokers.module').then(m => m.BrokersModule),
          },
          {
            path: 'administration/masters/clients',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'clients',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador', 'Comercial de Compra', 'Comercial de Venta', 'Gestor de Transporte', 'Manager','Gestor de Administración','Gestor de Ofertas', 'Gestor Documental']
            },
            loadChildren: () => import('./pages/pages/administration/masters/clients/clients.module').then(m => m.ClientsModule),
          },
          {
            path: 'administration/masters/colors',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'colors',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor de Tablas Maestras']
            },
            loadChildren: () => import('./pages/pages/administration/masters/colors/colors.module').then(m => m.ColorsModule),
          },
          {
            path: 'administration/masters/commercials',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'commercials',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador', 'Gestor de Administración']
            },
            loadChildren: () => import('./pages/pages/administration/masters/commercials/commercials.module').then(m => m.CommercialsModule),
          },
          {
            path: 'administration/masters/countries',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'countries',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor de Tablas Maestras']
            },
            loadChildren: () => import('./pages/pages/administration/masters/countries/countries.module').then(m => m.CountriesModule),
          },
          {
            path: 'administration/masters/products',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'products',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador']
            },
            loadChildren: () => import('./pages/pages/administration/masters/products/products.module').then(m => m.ProductsModule),
          },
          {
            path: 'administration/masters/providers',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'externalservices',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador', 'Comercial de Compra', 'Comercial de Venta', 'Gestor de Transporte', 'Manager','Gestor de Administración','Gestor de Ofertas', 'Gestor Documental']
            },
            loadChildren: () => import('./pages/pages/administration/masters/externalservices/externalservices.module').then(m => m.ExternalServicesModule),
          },
          {
            path: 'providers',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador', 'Comercial de Compra', 'Comercial de Venta', 'Gestor de Transporte', 'Manager','Gestor de Administración','Gestor de Ofertas', 'Gestor Documental']
            },
            loadChildren: () => import('./pages/pages/administration/masters/providers/providers.module').then(m => m.ProvidersModule),
          },
          {
            path: 'administration/masters/series',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'series',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor de Tablas Maestras']
            },
            loadChildren: () => import('./pages/pages/administration/masters/series/series.module').then(m => m.SeriesModule),
          },
          {
            path: 'administration/masters/cost-types',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'cost-types',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor de Tablas Maestras']
            },
            loadChildren: () => import('./pages/pages/administration/masters/cost-types/cost-types.module').then(m => m.CostTypesModule),
          },
          {
            path: 'administration/masters/titles',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'titles',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor de Tablas Maestras']
            },
            loadChildren: () => import('./pages/pages/administration/masters/titles/titles.module').then(m => m.TitlesModule),
          },
          {
            path: 'administration/masters/invoice-types',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'invoice-types',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor de Tablas Maestras']
            },
            loadChildren: () => import('./pages/pages/administration/masters/invoice-types/invoice-types.module').then(m => m.InvoiceTypesModule),
          },
          {
            path: 'administration/masters/freight-companies',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'freight-companies',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador', 'Comercial de Compra', 'Comercial de Venta', 'Gestor de Transporte', 'Manager','Gestor de Administración','Gestor de Ofertas', 'Gestor Documental']
            },
            loadChildren: () => import('./pages/pages/administration/masters/freight-companies/freight-companies.module').then(m => m.FreightCompaniesModule),
          }
        ]
      },
      {
        path: '',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador', 'Comercial de Compra', 'Comercial de Venta', 'Gestor de Transporte', 'Manager','Gestor de Administración','Gestor de Ofertas', 'Gestor Documental']
        },
        children: [
          {
            path: 'documentation/documents',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'documents',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador', 'Comercial de Compra', 'Comercial de Venta', 'Gestor de Transporte', 'Manager','Gestor de Administración','Gestor de Ofertas', 'Gestor Documental']
            },
            loadChildren: () => import('./pages/pages/documentation/documents/documents-and-sales.module').then(m => m.DocumentsAndSalesModule),
          },
          {
            path: 'documentation/category-types',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'category-types',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor Documental']
            },
            loadChildren: () => import('./pages/pages/documentation/category-types/category-types.module').then(m => m.CategoryTypesModule),
          },
          {
            path: 'documentation/categories',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'categories',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor Documental']
            },
            loadChildren: () => import('./pages/pages/documentation/categories/categories.module').then(m => m.CategoriesModule),
          },
          {
            path: 'documentation/tracing',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'tracing',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor Documental']
            },
            loadChildren: () => import('./pages/pages/documentation/tracing/tracing-table.module').then(m => m.TracingTableModule),
          }
        ]
      },
      {
        path: '',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador',' Gestor de Administración','Gestor Documental','Manager','Comercial de Compra','Comercial de Venta']
        },
        children: [
          {
            path: 'expedients/sales',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'sales',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador',' Gestor de Administración','Gestor Documental','Manager','Comercial de Compra','Comercial de Venta']
            },
            loadChildren: () => import('./pages/pages/expedients/sales/sales-table.module').then(m => m.SalesTableModule),
          },
          {
            path: 'expedients/purchases',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'purchases',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador',' Gestor de Administración','Gestor Documental','Manager','Comercial de Compra','Comercial de Venta']
            },
            loadChildren: () => import('./pages/pages/expedients/purchases/purchases-table.module').then(m => m.PurchasesTableModule),
          },
        ]
      },
      {
        path: '',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador','Gestor de Transporte','Comercial de Venta','Manager']
        },
        children: [
          {
            path: 'transports/budgets',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'budgets',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Comercial de Venta','Manager']
            },
            loadChildren: () => import('./pages/pages/transports/budgets/budgets-table.module').then(m => m.BudgetsTableModule),
          },
          {
            path: 'transports/pending',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'pending',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor de Transporte','Manager']
            },
            loadChildren: () => import('./pages/pages/transports/pending/pending-table.module').then(m => m.PendingTableModule),
          },
          {
            path: 'transports/daily',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'management',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor de Transporte','Manager']
            },
            loadChildren: () => import('./pages/pages/transports/management/management-table.module').then(m => m.ManagementTableModule),
          },
          {
            path: 'transports/daily',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'daily',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor de Transporte','Manager']
            },
            loadChildren: () => import('./pages/pages/transports/daily/daily-table.module').then(m => m.DailyTableModule),
          }
        ]
      },
      {
        path: '',
        canActivate: [AdminGuard],
        data: {
          roles: ['Administrador','Gestor de Ofertas','Comercial de Compra','Comercial de Venta','Manager','Gestor de Administración']
        },
        children: [
          {
            path: 'offers-manager/offers',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'offers',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor de Ofertas','Comercial de Compra','Comercial de Venta','Manager','Gestor de Administración']
            },
            loadChildren: () => import('./pages/pages/offers/offers/offers-table.module').then(m => m.OffersTableModule),
          },
          {
            path: 'offers-manager/offer',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'offer',
            canActivate: [AdminGuard],
            data: {
              roles: ['Administrador','Gestor de Ofertas','Comercial de Compra','Comercial de Venta','Manager','Gestor de Administración']
            },
            loadChildren: () => import('./pages/pages/offers/offers/offer-create-update/offer-create-update.module').then(m => m.OfferCreateUpdateModule),
          }
        ]
      },
      /* {
        path: 'commercial',
        children: [
          {
            path: 'commercial/contacts',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'contacts',
            loadChildren: () => import('./pages/pages/commercial/contacts/contacts-table.module').then(m => m.ContactsTableModule),
          }
        ]
      }, */
      {
        path: 'pages',
        children: [
          {
            path: 'error-404',
            loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
          },
          {
            path: 'error-500',
            loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
          }
        ]
      },
      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule],
  providers: [CanDeactivateGuard]
})
export class AppRoutingModule {
}

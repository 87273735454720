import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Input } from '@angular/core';
import { BehaviorSubject, Observable, map, lastValueFrom, firstValueFrom } from 'rxjs';
import { CommonListResponse } from 'src/app/models/common-list-response';
import { KeyValue } from 'src/app/models/key-value';
import { MasterCommon } from 'src/app/models/masters/master-common';
import { mergeMap, retry } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { IModel } from 'src/app/interfaces/model';
import { MastersConst } from 'src/app/constants/masters';
import { CommonFilter } from 'src/app/models/masters/common-filter';
import { IFilters } from 'src/app/interfaces/ifilters';
import { IService } from './iService';
import { CommonItemList } from 'src/app/models/common-item';


@Injectable({
    providedIn: 'root',
})
export class MasterService<T> implements IService<T> {
        private masterSubject: BehaviorSubject<CommonListResponse<T>>;

    data: Array<T>;

    constructor(private http: HttpClient) {
    }

    public get currentUserValue(): CommonListResponse<T> {
        return this.masterSubject.value;
    }

    async getAllMasters()
    {
        MastersConst.forEach( master => {
           this.get(master)
        });
    }

    async getItems(masterTable: string) {
      
      const options = {
          headers: new HttpHeaders({  
              Accept: 'application/json',
              'Content-Type': '*/*',
              Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
          })
      };
      return await lastValueFrom(this.http.get<CommonListResponse<T>>(`${environment.apiUrl + masterTable}/get`, options)
          .pipe(
              map(response => {
                  localStorage.setItem(masterTable, JSON.stringify(response.data));
                  return response.data;
              })));
        
    }

    async get(masterTable: string) {
        this.data = JSON.parse(localStorage.getItem(masterTable));
        if (!this.data || this.data?.length <= 0)
            return await this.getItems(masterTable)
            
        return this.data;
    }

    getSync(masterTable: string) {

      
      const options = {
          headers: new HttpHeaders({
              Accept: 'application/json',
              'Content-Type': '*/*',
              Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
          })
      };
      return this.http.get<CommonListResponse<T>>(`${environment.apiUrl + masterTable}/get`, options)
          .pipe(
              map(response => {
                  localStorage.setItem(masterTable, JSON.stringify(response.data));
                  return response.data;
              }));
  
}

  async getAllItems(masterTable: string) {

      
      const options = {
          headers: new HttpHeaders({
              Accept: 'application/json',
              'Content-Type': '*/*',
              Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
          })
      };
      return await lastValueFrom(this.http.get<CommonListResponse<T>>(`${environment.apiUrl + masterTable}/getAll`, options)
          .pipe(
              map(response => {
                  localStorage.setItem(masterTable, JSON.stringify(response.data));
                  return response.data;
              })));
  
}

async getAllItemsFilter(masterTable: string, item) {

      
  const options = {
      headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': '*/*',
          Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
  };
  return await lastValueFrom(this.http.post<CommonListResponse<T>>(`${environment.apiUrl + masterTable}/getAll`, { item }, options)
      .pipe(
          map(response => {
              return response.data;
          })));

}

async getAll(masterTable: string) {
  this.data = JSON.parse(localStorage.getItem(masterTable));
  if (!this.data || this.data?.length <= 0)
      return await this.getAllItems(masterTable)
      
  return this.data;
}
  /*   async getAllFilteredAsyn(service: string, filters: IFilters) {
     
      return await this.getItemsFiltered(service, filters);
          
    } */
    async list(service, filters: IFilters) {
      const options = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': '*/*',
          Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
        })
      };
      return await firstValueFrom(this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/list`, { filters }, options)
        .pipe(
          map(response => {
            return response.data;
          })));
    }
    async getAllFilteredAsync(service, filters: IFilters) {
      const options = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': '*/*',
          Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
        })
      };
      return await firstValueFrom(this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/get`, { filters }, options)
        .pipe(
          map(response => {
            return response.data;
          })));
    }

    getAllFiltered(service, filters: IFilters) {
      const options = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': '*/*',
          Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
        })
      };
      return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/get`, { filters }, options)
        .pipe(
          map(response => {
            return response.data;
          }));
    }

    update(masterTable: string, item: T) {
        const options = {
          headers: new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': '*/*',
            Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
          })
        };
        return this.http.post<CommonListResponse<IModel>>(`${environment.apiUrl + masterTable}/update`, { item }, options)
          .pipe(
            map(response => {
              if(localStorage.hasOwnProperty(masterTable))
                this.getItems(masterTable);
              return response;
            }));
      }

    any(service) {
      const options = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': '*/*',
          Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
        })
      };
      return this.http.get<CommonListResponse<T>>(`${environment.apiUrl + service}`, options)
        .pipe(
          map(response => {
            return response.data;
          }));
    }
    
    addRange<T>(service, item: CommonItemList<T>) {
      const options = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': '*/*',
          Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
        })
      };
      return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/register`, { item }, options)
        .pipe(
          map(response => {
            return response;
          }));
    }
    
    create(masterTable: string,item: T) {
        const options = {
          headers: new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': '*/*',
            Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
          })
        };
        return this.http.post<CommonListResponse<IModel>>(`${environment.apiUrl + masterTable}/register`, { item }, options)
          .pipe(
            map(response => {
              if(localStorage.hasOwnProperty(masterTable))
                this.getItems(masterTable);
              return response;
            }));
      }
    
      delete(masterTable: string,item: T) {
        const options = {
          headers: new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': '*/*',
            Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
          })
        };
        return this.http.post<CommonListResponse<IModel>>(`${environment.apiUrl + masterTable}/delete`, { item }, options)
          .pipe( 
            map(response => {
              if(localStorage.hasOwnProperty(masterTable))
                this.getItems(masterTable);
              return response;
            }));
      }

}
import { NgModule } from "@angular/core";
import { BrokerType } from "./broker-type";
import { ClientType } from "./client-type";
import { CommercialType } from "./commercial-type";
import { FreightCompanyType } from "./freight-company-type";
import { ProviderType } from "./provider-type";
import { OtherType } from "./other-type";
import { ExternalServiceType } from "./externalService-type";

@NgModule({
    providers: [BrokerType, CommercialType, ClientType, ProviderType, FreightCompanyType, ExternalServiceType, OtherType]
})
export class ContactTypesFactoryModule {
}

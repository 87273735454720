import { Component, EventEmitter, Input, isDevMode, Output, ViewChild } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { CreateUpdateComponent } from './create-update/create-update.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { ConfirmDialogComponent } from 'src/app/pages/apps/common-dialogs/confirm-dialog/confirm-dialog.component';
import { IModel } from 'src/app/interfaces/model';
import { Item } from './models/item';
import { ITableParams } from './models/table-params';
import { ListComponent } from './list/list.component';
import { MasterService } from 'src/app/services/api/masters.service';
import { packsTableData } from 'src/app/mocks/packs';
import { ComboItem } from 'src/app/models/combo-item';
import { ToastService } from 'src/app/services/toast/toast.service';
import { _isNumberValue } from '@angular/cdk/coercion';
import { CommonService } from 'src/app/services/api/common.service';
import { IFilters } from 'src/app/interfaces/ifilters';
import { CommonItemList } from 'src/app/models/common-item';
import { EmptyKeyValue } from 'src/app/models/key-value';
import { Router } from '@angular/router';
import { isNgTemplate } from '@angular/compiler';

@Component({
  selector: 'vex-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class TableComponent<T extends IModel> {

  @Output() event = new EventEmitter<any>();
  layoutCtrl = new UntypedFormControl('fullwidth');


  @Input() params: ITableParams<T, T>;

  /*   _params: ITableParams<T, T>;
  
  
    @Input()
    set params(val: ITableParams<T, T>) {
      this.paramsChange.emit(val);
      this._params = val;
    }
    get params() {
      return this._params;
    } */
  @Output()
  paramsChange: EventEmitter<ITableParams<T, T>> = new EventEmitter<ITableParams<T, T>>();


  _tableData: Array<T>;

  @Input()
  set tableData(val: Array<T>) {
    this.Change.emit(val);
    this._tableData = val;
  }
  get tableData() {
    //this.tableData = this.params?.list?.tableData;
    return this._tableData;
  }

  _settledAmount: number;

  @Input()
  set settledAmount(val: number) {
    
    //if(this.params?.list?.extraValue)
    //this.params.list.extraValue = val as unknown as object;
    this._settledAmount = val;
    this.updatedSettledAmount.emit(val);
  }
  get settledAmount() {
    //this.tableData = this.params?.list?.tableData;
    return this._settledAmount;
  }


  _isUpdated: boolean;
  @Output() updateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() updatedSettledAmount: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  set isUpdated(val: boolean) {
    this.updateChange.emit(val);
    this._isUpdated = val;
  }
  get isUpdated() {
    //this.tableData = this.params?.list?.tableData;
    return this._isUpdated;
  }


  packs: Array<ComboItem> = packsTableData.map(packs => new ComboItem(packs));

  @Output() Change: EventEmitter<Array<T>> = new EventEmitter<Array<T>>();

  dataSource: MatTableDataSource<T> = new MatTableDataSource();

  doesDataExist: boolean = false;

  subject$: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);
  data$: Observable<T[]> = this.subject$.asObservable();
  items: T[] = [];


  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 20, 50];

  selection = new SelectionModel<T>(true, []);
  searchCtrl = new UntypedFormControl();



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private dialog: MatDialog,
    private router: Router,
    private masterService: MasterService<IModel>,
    private alert: ToastService,
    private commonService: CommonService<T, IFilters>,) {
  }

  get visibleColumns() {
    return this.params.columns.filter(column => column.visible).map(column => column.property);
  }

  get filterVisibleColumns() {
    return this.params.columns.filter(column => column.type != "checkbox");
  }

  receiveChildData(data) {
    this.params.list.tableData = data;
  }
  async getData() {
    if (this.params.tableData) {
      if (this.params.pageSize)
        this.pageSize = this.params.pageSize;
      return of(this.params.tableData);
    }
    else if (this.params.service) {
      if (this.params.filters)
        return of(await this.masterService.getAllFilteredAsync(this.params.service, this.params.filters) as unknown as Array<T>);
      /*   (await this.masterService.getAllFiltered(this.params.extraValue, this.params.filters)).subscribe(items => {
       return of(items as unknown as Array<T>);
     }); */
      else if(this.params.refreshCache)
        return of(await this.masterService.getItems(this.params.service) as unknown as Array<T>);
      else
        return of(await this.masterService.get(this.params.service) as unknown as Array<T>);
    }

    else
      return of([]);
  }

  /*   async loadFilterData<T>(service: string, filters: IFilters) : Promise<Array<T>>{
      await this.masterService.getAllFiltered(service, filters).subscribe({
        next:
          response => {
            if (response && response.isSuccessful) {
              return response.data;
            }
          },
      })
      return [];
    } */

  ngAfterContentInit() {

    this.loadData();
  }

  async loadData() {

    this._settledAmount = this.params.extraValue as unknown as number;

    (await (this.getData())).subscribe(items => {
      this.subject$.next(items);
    });

    if (this.params.root)
      this.params.root.push(this.params.title);

    this.dataSource = new MatTableDataSource();


    //this.dataSource = new MatTableDataSource(this.tableData);
    this.data$.pipe(
      filter<T[]>(Boolean)
    ).subscribe(items => {
      this.items = items;
      this.dataSource.data = items;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (obj: any, property: string) => property.split('.').reduce((o, p) => o && o[p], obj);
      this.dataSource.sort = this.sort;
    });

    this.searchCtrl.valueChanges.pipe(
      //  untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));

    this.dataSource.filterPredicate = (data: any, filter) => {
      const dataStr = JSON.stringify(data).toLowerCase(); return dataStr.indexOf(filter) != -1;
    }



  }

  pathDataAccessor(item: any, path: string): any {
    return path.split('.')
      .reduce((accumulator: any, key: string) => {
        return accumulator ? accumulator[key] : undefined;
      }, item);
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }

  sendExtraValue(value: string) {
    this.event.emit(value);
    // this.loadData();
    /*   this.event.subscribe((data) => {
        return this.items.unshift(data);
      }); */
  }
  createItem() {
    if (this.params.list?.extraValue != undefined && this.params.list?.extraValue as unknown as number == 0) {
      this.alert.info('Sin importe pendiente');
    }
    else {
      if (this.params.list !== undefined) {
        let dialogRef = this.dialog.open(ListComponent,
          {
            data: this.params.list
          });
        const sub = dialogRef.componentInstance.onAdd.subscribe((data) => {
          this.sendExtraValue(data);
        });
        dialogRef.afterClosed().subscribe((items: T[]) => {
          if (items && this.params.filters) {
            /*  if (this.params.service=="offers/equipmentnotes" || this.params.service=="offers/standardequipments" || this.params.service=="offers/optionalequipments")
                this.params.service = this.params.list.service; */
            if (this.params.service == "accountingentries/bt") {
              items.forEach(x => x.amountSett = +(x.amountSett - x.amountSettLoaded).toFixed(2));
            }
          /*   else if (this.params.service == "accountingentries/bt") {
              items.forEach(x => x.amountSett = x.amountSett - x.amountSettLoaded);
            } */

            let item: any;

            if (this.params.linkService == "accountingentries/costs/administrativefiles" && this.params?.filters?.administrativeFileId) {
              let ids: Array<EmptyKeyValue> = [];
              let id = new EmptyKeyValue();
              id.id = this.params?.filters?.administrativeFileId;
              ids.push(id);
              item = new CommonItemList<T>(null, ids);
              item.isCostOrigin = this.params?.filters?.isCostOrigin;
              item.accountingEntries = items;

              //item.accountingEntry = ids;

            }
            else {
              item = new CommonItemList<T>(this.params.filters.id, items);
              item.isCostOrigin = this.params?.filters?.isCostOrigin;
              let ids = new EmptyKeyValue();
              if (this.params.filters.id)
                ids.id = this.params.filters.id;

              if (this.params?.filters?.administrativeFileId) {
                item.id = this.params.filters.administrativeFileId;
                ids.id = this.params.filters.administrativeFileId;
                item.administrativeFiles = [];
                item.administrativeFiles.push(ids)
                item.administrativeFile = ids;
              }
              else if (this.params?.filters?.bankingTransactionId) {
                item.id = this.params.filters.bankingTransactionId;
                ids.id = this.params.filters.bankingTransactionId;
                item.bankingTransactions = [];
                item.bankingTransactions.push(ids)
                item.bankingTransaction = ids;
              }
              else if (this.params?.filters?.accountingEntryId) {
                item.id = this.params.filters.accountingEntryId;
                ids.id = this.params.filters.accountingEntryId;
                item.accountingEntries = [];
                item.accountingEntries.push(ids)
                item.accountingEntry = ids;
              }



            }

            if (this.params.list.linkService) {
              this.items.unshift(...items);
              this.subject$.next(this.items);
              this.tableData = this.items;
            }
            else {
              this.commonService.addRange<T>(this.params.linkService || this.params.service, item).subscribe({
                next:
                  response => {
                    if (!response) {
                      this.alert.error("No se han podido añadir " + this.params.title);
                    }
                    else if (!response.isSuccessful) {
                      this.alert.info(response.message);
                    }
                    else {
                      if (this.params?.list?.uniqueSelect) {
                        this.items = [];
                      }
                      if (this.params.service == "offers/optionalequipments"
                        || this.params.service == "offers/standardequipments") {
                        items = response.data;
                        if (!response.data || response.data.length == 0) {
                          this.alert.info("No hay básicos asignados a esta selección");
                          items = [];
                        }

                      }
                      else {
                        if (response.data && response.data.length > 0) {
                          items = response.data;
                          if (this.params.service == 'accountingentries/costs' || this.params.service =='bankingTransactions/accountingentries') {
                            this.settledAmount = Number(this.params.list.extraValue) - (Number(response.data.map(t =>  t.settledAmount).reduce((sum, value) => Number(sum) + Number(value), 0)));
                            //this.settledAmount = Number(this.params.list.extraValue);
                            //response.data.map(t => t.amount = (t.amount * this.params.list.filters.sign));


                          }
                          else if(this.params.service == "accountingentries/bt"){
                            this.settledAmount = Number(this.params.list.extraValue) - (Number(response.data.map(t =>  t.amountSett).reduce((sum, value) => Number(sum) + Number(value), 0)));
                          }
                          else if (this.params.service == "accountingentries/vehicles") {

                            this.settledAmount = Number(this.params.list.extraValue) -response.data.map(t => t.amountPay).reduce((sum, value) => Number(sum) + Number(value), 0);

                          }

                        }
                        else {
                          this.alert.info("Todos los elementos ya incluidos");
                          items = [];
                        }
                        this.tableData = items;
                      }
                      if (this.params.reload) {
                        this.loadData();
                      }
                      else {
                        const length = this.items.length;
                        this.items.unshift(
                          ...items.filter(
                            a => !this.items.find(
                              b => b.id === a.id)
                          )
                        );
                        if (this.items.length == length)
                          this.alert.info("Ya incluidos");
                        //this.items.unshift(...items);
                        this.subject$.next(this.items);
                        this.isUpdated = true;
                      }
                      /* this.item.id = response.data[0].id;
                      this.item.languages = this.form.value.languages;
                      this.dialogRef.close(this.item); */
                    }
                    this.selection.clear();
                  },
                error:
                  (e) => {
                    if (e.status == '401') {
                      this.router.navigate(['/login']);
                      this.alert.info("Sesión expirada")
                      return;
                    }
                    if (isDevMode)
                      this.alert.error(e.message)
                    else
                      this.alert.error("No se ha podido añadir " + this.params.title);
                  }
              });
            }

          }
          else {
            if (items) {
              const repeated = items.filter(a => this.items.some(b => b.id == a.id));
              repeated?.forEach(x=>{
                items.splice(items.findIndex(y => y.id === x.id), 1);
              })
              
              this.items.unshift(...items);
              this.subject$.next(this.items);
              this.tableData = this.items;
              this.selection.clear();
            }
          }
        });
      }
      else {
        this.dialog.open(CreateUpdateComponent,
          {
            data: new Item(
              {
                filters: this.params.filters,
                element: this.params.element,
                hasImage: this.params.hasImage,
                extraValue: this.params.extraValue,
                service: this.params.service,
                fields: this.params.columns,
                titleProperty: this.params.titleProperty
              })
          }).afterClosed().subscribe((item: T) => {

            if (item) {
              if (!Array.isArray(this.items))
                this.items = [];

              if (this.params.service == "accountingentries/costs/concepts")
                this.settledAmount = Number(item.amount);

              this.items.unshift(item);
              this.subject$.next(this.items);
              this.selection.clear();
            }
          });
      }
    }
  }

  updateItem(item: T) {
    let changed: boolean = false;
    if (this.params.list !== undefined && !this.params.list.isMenu) {
      this.selection.select(item);
    }
    else {
      /*  if (this.params.service=="offers/equipmentnotes" || this.params.service=="offers/standardequipments" || this.params.service=="offers/optionalequipments")
         this.params.service = this.params.list.service; */
      if (this.params.service == "offers/equipmentnotes"){
        this.params.service = this.params.list.service;
        changed = true;
      } 
        
      this.dialog.open(CreateUpdateComponent, {
        data: new Item(
          {
            filters: this.params.filters,
            element: this.params.element,
            extraValue: this.params.extraValue,
            service: this.params.service,
            hasImage: this.params.hasImage,
            fields: this.params.columns,
            titleProperty: this.params.titleProperty,
            values: item
          })
      }).afterClosed().subscribe(updated => {

        if (changed){
          this.params.service = "offers/equipmentnotes";
          changed = false;
        }
          
        if (updated) {

          const index = this.items.findIndex((existingItem) => existingItem.id === updated.id);
          if (this.params.service == "accountingentries/costs/concepts")
            this.settledAmount = Number(updated.amount) - Number(item.amount);

          this.items[index] = updated;
          this.subject$.next(this.items);
          this.selection.clear();
        }
      });
    }
  }

  // Para contactos
  duplicateItem(item: T) {
    let changed: boolean = false;
    if (this.params.list !== undefined && !this.params.list.isMenu) {
      this.selection.select(item);
    }
    else {
        
      this.dialog.open(CreateUpdateComponent, {
        data: new Item(
          {
            filters: this.params.filters,
            element: this.params.element,
            extraValue: this.params.extraValue,
            service: this.params.service,
            hasImage: this.params.hasImage,
            fields: this.params.columns,
            titleProperty: this.params.titleProperty,
            values: item,
            mode: 'duplicate'
          })
      }).afterClosed().subscribe((item: T) => {

        if (item) {
          if (!Array.isArray(this.items))
            this.items = [];

          this.items.unshift(item);
          this.subject$.next(this.items);
          this.selection.clear();
        }
      });
    }
  }

  showDeleteItemsAlert(items: T[]): void {
    if (items.length == 0) {
      return;
    }
    else if (items.length == 1) {
      this.showDeleteItemAlert(items[0]);
      return;
    }


    this.dialog
      .open(ConfirmDialogComponent, {
        data: `¿Está seguro de querer borrar los  ` + this.params.title.toLowerCase() + ` seleccionados?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.deleteItems(items);
        }
      });
  }

  showDeleteItemAlert(item: T): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: `¿Está seguro de querer borrar este ` + this.params.element + `?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.deleteItem(item);
        }
      });
  }

  showDeleteAsociada(item: T): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: `¿Está seguro de querer borrar la ` + this.params.element + `?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.deleteAsociada();
        }
      });
  }

  deleteAsociada() {
    const item = this.params.filters as IModel;
    this.masterService.delete("shipments/request/links", item).subscribe({
      next:
        async response => {
          if (!response) {
            this.alert.error("No se ha podido borrar la solicitud asociada");
          }
          else if (!(await response).isSuccessful) {
            this.alert.info((await response).message);
          }
          else {
            this.params.tableData = [];
            this.loadData();
          }
        },
      error:
        (e) => {
          if (e.status == '401') {
            this.router.navigate(['/login']);
            this.alert.info("Sesión expirada")
            return;
          }
          if (isDevMode)
            this.alert.error(e.message)
          else
            this.alert.error("No se ha podido borrar la solicitud asociada");
        }
    });
  }
  deleteItem(item: T) {
    if (this.params.service) {
      item.entity = this.params.filters;
      let service: string = this.params.service;
      if (this.params.deleteService)
        service = this.params.deleteService;

      if (service == "bankingTransactions/accountingentries" && this.params?.filters?.bankingTransactionId) {
        item.entity = new EmptyKeyValue(item.id);
        item.id = this.params.filters.bankingTransactionId;

      }

      let finalItem: any;

      if (this.params.deleteService == "accountingentries/costs/administrativefiles" && this.params?.filters?.accountingEntryId) {
        let ids: Array<T> = [];
        ids.push(item);
        finalItem = new CommonItemList<T>(null, ids);
        finalItem.administrativeFiles = [];
        finalItem.administrativeFiles.push(item);
        finalItem.accountingEntries = [];
        finalItem.accountingEntries.push(new EmptyKeyValue(this.params?.filters?.accountingEntryId));

        //item.accountingEntry = ids;

      }
      else if (this.params.deleteService == "accountingentries/costs/administrativefiles" && this.params?.filters?.administrativeFileId) {
        let ids: Array<T> = [];
        ids.push(item);
        finalItem = new CommonItemList<T>(null, ids);
        finalItem.administrativeFiles = [];
        finalItem.administrativeFiles.push(new EmptyKeyValue(this.params?.filters?.administrativeFileId));
        finalItem.accountingEntries = [];
        finalItem.accountingEntries.push(item);

      }
      /*  else if(this.params.deleteService == "bankingTransactions/accountingentries"  && this.params?.filters?.bankingTransactionId){
         let ids: Array<EmptyKeyValue> = [];
         ids.push(new EmptyKeyValue(item.id));
         finalItem = new CommonItemList<T>(null, ids);
         finalItem.entity = item;
         finalItem.id = this.params?.filters?.bankingTransactionId;
       } */
      else {
        finalItem = item;
      }

      this.masterService.delete(service, finalItem).subscribe({
        next:
          async response => {
            this.selection.deselect(item);
            if (!response) {
              this.alert.error("No se ha podido borrar el usuario " + item.name);
            }
            else if (!(await response).isSuccessful) {
              this.alert.info((await response).message);
            }
            else {
              if (this.params.service == 'accountingentries/costs' || this.params.service =='bankingTransactions/accountingentries') {
                this.settledAmount = Number(this._settledAmount || 0) + Number(item?.settledAmount || 0);
                //this.settledAmount = Number(this.params.list.extraValue || 0); //(this.params?.list?.extraValue || 0 as unknown as number + Number(item?.amount)) as number;
              }
              else if (this.params.service == "accountingentries/bt") {
                this.settledAmount = Number(this._settledAmount || 0) + Number(item?.amountSett || 0);
                //this.settledAmount = Number(this.params.list.extraValue || 0); //(this.params?.list?.extraValue || 0 as unknown as number + Number(item?.amount)) as number;
              }
              else if (this.params.service == "accountingentries/costs/concepts")
                this.settledAmount = Number(item.amount) * -1;
              else if (this.params.service == "accountingentries/vehicles")
                this.settledAmount = Number(this._settledAmount || 0) + Number(item?.amountPay || 0);

              this.items.splice(this.items.findIndex((existingItem) => existingItem.id === item.id), 1);
              this.subject$.next(this.items);
              this.isUpdated = true;
            }
          },
        error:
          (e) => {
            if (e.status == '401') {
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No se ha podido borrar el usuario " + item.name);
          }
      });
    }
    else if(this.params?.list?.service && this.params?.list?.service === 'proformas/invoices/preview'){
      if(this.params?.list?.filters?.shipmentCostType?.id === 2){
      
        if(item.vehicleCostType.id == 1)
          this.items.splice(this.items.findIndex(x=> x.vehicle.id == item.vehicle.id && x.vehicleCostType?.id == 2), 1);
      }
        this.items.splice(this.items.findIndex((existingItem) => existingItem.id === item.id), 1);
        this.selection.deselect(item);
        this.subject$.next(this.items);
        this.isUpdated = true;
      
    }
    else{
      this.items.splice(this.items.findIndex((existingItem) => existingItem.id === item.id), 1);
      this.selection.deselect(item);
      this.subject$.next(this.items);
      this.isUpdated = true;
    }

  }

  checkTransport(row) {
    if (this.params?.list?.service && this.params?.list?.service == 'proformas/invoices/preview' && row.vehicleCostType.id == 2 && this.params?.list?.filters?.shipmentCostType?.id == 2) {
      return false;
    }
    return true;

  }

  deleteItems(items: T[]) {
    /**
     * Here we are updating our local array.
     * You would probably make an HTTP request here.
     */
    items.forEach(c => this.deleteItem(c));
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  /*   isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    } */

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const page = this.dataSource['_renderData']['_value'].length;
    return numSelected === page;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.selectRows();
  }
  selectRows() {
    let total = this.pageSize;
    let initIndex: number = 0;
    if (this.pageIndex > 0 && this.dataSource['_renderData']['_value'].length !== this.dataSource.data.length) {
      initIndex = (this.pageIndex) * this.pageSize;
      total = this.pageSize * (this.pageIndex + 1);
    }
    for (let index = initIndex; index < total; index++) {
      if (this.dataSource.data[index])
        this.selection.select(this.dataSource.data[index]);
    }
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onLabelChange(change: MatSelectChange, row: T) {
    const index = this.items.findIndex(c => c === row);
    //this.articles[index].labels = change.value;
    this.subject$.next(this.items);
  }

  mouseLeave(trigger) {
    trigger.closeMenu();
  }

  closeMenu(trigger) {
    trigger.closeMenu();
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )
  getColor(amount) {
    if (amount < 0)
      return 'red'
    else
      return ''

  }
  onPaginateChange(event) {
    this.selection.clear();
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }
  getPadding() {
    if (!this.params.isMenu) {
      return "24px 0px 24px 0px"
    }
  }
}
